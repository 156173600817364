import React, { useState, Fragment, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";

import _ from "lodash";

import styled from "styled-components";

//helper
import {secureText} from "../../../helpers/helpers"

//toast
import toaster, { Toaster } from "react-hot-toast";

//captcha google
import ReCAPTCHA from "react-google-recaptcha";

//icon
import * as IoIcons from "react-icons/io";
import * as MdIcons from "react-icons/md";
import * as BsIcons from "react-icons/bs";
import TextInput from "../../Input/TextInput";

//redux
import { useSelector, useDispatch } from "react-redux";
import { userLogout } from "../../../redux/action";

//services
import OTPService from "../../../services/otp.service";
import UserService from "../../../services/user.service";

//form
import FormChangeEmail from "./Form/FormChangeEmail";
import FormVerifyOTP from "./Form/FormVerifyOTP";
import FormChangePassword from "./Form/FormChangePassword";
import FormChangeQuestion from "./Form/FormChangeQuestion";

//encode
import encoder from "../../../helpers/encoder";

//helpers
import { checkValidPassword, checkValidateEmail } from "../../../helpers/helpers";

const Information = () => {
  const dispatch = useDispatch();

  //ref captcha
  const reRef = useRef();

  // open modal
  const [type, setType] = useState({ type: "", isOpen: false });

  const userProfile = useSelector((state) => state.userReducer.user);
  const [profile, setProfile] = useState(userProfile);
  const [checkOTP, setCheckOTP] = useState(false);
  const [selected, setSelected] = useState(userProfile.questions[0]);

  const onChangeValue = (e) => {
    const target = e.target;
    const name = target.name;
    setProfile({ ...profile, [name]: target.value });
  };

  const [editProfile, setEditProfile] = useState({
    oldEmail: "",
    newEmail: "",
    oldPassword: "",
    newPassword: "",
    reNewPassword: "",
    oldAnswer: "",
    newAnswer: "",
  });
  const onEditProfile = (e) => {
    const target = e.target;
    const name = target.name;
    setEditProfile({ ...editProfile, [name]: target.value });
  };

  const checkEmail = () => {
    if (!checkValidateEmail(editProfile.oldEmail)) {
      toaster.error("Current email is invalid !!!");
      return false;
    }
    if (!checkValidateEmail(editProfile.newEmail)) {
      toaster.error("New email is invalid !!!");
      return false;
    }
    if (editProfile.oldEmail === editProfile.newEmail) {
      toaster.error("Email already in use !!!");
      return false;
    }
    return true;
  };

  const checkPassword = () => {
    if (_.isEmpty(editProfile.newPassword) || _.isEmpty(editProfile.oldPassword) || _.isEmpty(editProfile.reNewPassword)) {
      toaster.error("Please enter input fields");
      return false;
    }
    // if (!checkValidPassword(editProfile.oldPassword)) {
    //   toaster.error("Password must be at least eight characters, including a lowercase letter, a number and an uppercase letter");
    //   return false;
    // }
    if (!checkValidPassword(editProfile.newPassword)) {
      toaster.error("Password must be at least eight characters, including a lowercase letter, a number and an uppercase letter");
      return false;
    }
    if (editProfile.newPassword === editProfile.oldPassword) {
      toaster.error("Current password and new password must be different !!!");
      return false;
    }
    if (editProfile.newPassword !== editProfile.reNewPassword) {
      toaster.error("Confirm password is incorrect !!!");
      return false;
    }
    return true;
  };

  //submit form
  const onSubmit = async (e) => {
    e.preventDefault();
    const reCaptcha = await reRef.current.executeAsync();
    if (type.type === "changeEmail") {
      if (checkEmail()) {
        // get otp
        await OTPService.createOTP(editProfile, reCaptcha, type.type)
          .then((response) => {
            const { message } = response.data;
            toaster.success(message);
            setCheckOTP(true);
          })
          .catch((error) => {
            if (error.response.status === 404) {
              return toaster.error("Network error , please try again after a few minutes");
            }
            const { message } = error.response.data;
            toaster.error(message);
          });
      }
    }
    //change password
    if (type.type === "changePassword") {
      const data = {
        newPassword: encoder.MD5Encrypt(editProfile.newPassword),
        oldPassword: encoder.MD5Encrypt(editProfile.oldPassword),
        reNewPassword: encoder.MD5Encrypt(editProfile.reNewPassword),
      };
      if (checkPassword()) {
        await UserService.changePassword(data, reCaptcha)
          .then((response) => {
            const { message } = response.data;
            toaster.success(message);
            dispatch(userLogout(false));
          })
          .catch((error) => {
            if (error.response.status === 404) {
              return toaster.error("Network error , please try again after a few minutes");
            }
            const { message } = error.response.data;
            toaster.error(message);
          });
      }
    }

    //change question
    if (type.type === "changeQuestion") {
      const data = {
        oldAnswer: editProfile.oldAnswer,
        newQuestion: selected,
        newAnswer: editProfile.newAnswer,
      };
      await UserService.changeQuestion(data, reCaptcha)
        .then((response) => {
          const { message } = response.data;
          toaster.success(message);
          dispatch(userLogout(false));
        })
        .catch((error) => {
          if (error.response.status === 404) {
            return toaster.error("Network error , please try again after a few minutes");
          }
          const { message } = error.response.data;
          toaster.error(message);
        });
    }
  };

  //otp verify
  const [otp, setOTP] = useState("");
  const onVerifyOTP = async (e) => {
    e.preventDefault();
    const reCaptcha = await reRef.current.executeAsync();
    await OTPService.verifyOTP(otp, reCaptcha, type.type)
      .then((response) => {
        const { message } = response.data;
        toaster.success(message);
        setType({ isOpen: false, type: "" });
        dispatch(userLogout(false));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return toaster.error("Network error , please try again after a few minutes");
        }
        const { message } = error.response.data;
        toaster.error(message);
      });
  };

  //update Profile
  const onUpdateProfile = async (e) => {
    e.preventDefault();
    const reCaptcha = await reRef.current.executeAsync();
    const data = {
      fullname: profile.fullname,
    };
    await UserService.updateProfile(data, reCaptcha)
      .then((response) => {
        const { message } = response.data;
        toaster.success(message);
        dispatch(userLogout(false));
      })
      .catch((error) => {
        if (error.response.status === 404) {
          return toaster.error("Network error , please try again after a few minutes");
        }
        const { message } = error.response.data;
        toaster.error(message);
      });
  };
  // onclose popup
  const onClose = () => {
    setType({ isOpen: false, type: "" });
    setEditProfile({ oldEmail: "", newEmail: "", oldPassword: "", newPassword: "", reNewPassword: "", oldAnswer: "", newAnswer: "" });
    setCheckOTP(false);
  };
  return (
    <>
      {/* toast */}
      <Toaster position="top-center" reverseOrder={true} toastOptions={{ style: { fontSize: 14 } }} />

      {/* modal edit*/}
      <Transition appear show={type.isOpen} as={Fragment}>
        <Dialog as="div" className="fixed inset-0 z-50 overflow-hidden" onClose={onClose}>
          <div className="min-h-screen text-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0" />
            </Transition.Child>

            {/* This element is to trick the browser into centering the modal contents. */}
            <span className="inline-block h-screen align-middle" aria-hidden="true">
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <div
                style={{ height: "100vh" }}
                className="w-full inline-flex justify-center items-start align-middle overflow-hidden bg-black bg-opacity-70"
              >
                <form onSubmit={checkOTP ? onVerifyOTP : onSubmit} method="post">
                  {checkOTP ? (
                    <FormVerifyOTP onClose={onClose} otp={otp} setOTP={setOTP} />
                  ) : (
                    (type.type === "changeEmail" && (
                      <FormChangeEmail onClose={onClose} onEditProfile={onEditProfile} editProfile={editProfile} />
                    )) ||
                    (type.type === "changePassword" && (
                      <FormChangePassword onClose={onClose} onEditProfile={onEditProfile} editProfile={editProfile} />
                    )) ||
                    (type.type === "changeQuestion" && (
                      <FormChangeQuestion
                        onClose={onClose}
                        onEditProfile={onEditProfile}
                        editProfile={editProfile}
                        questions={profile.questions}
                        question={profile.question}
                        selected={selected}
                        setSelected={setSelected}
                      />
                    ))
                  )}
                  {/* captcha */}
                  <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} size="invisible" ref={reRef} />
                </form>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition>

      <WrapInfromation>
        <Box method="post" onSubmit={onUpdateProfile}>
          <TextInput
            type="text"
            placeholder="Full Name"
            iconLeft={<IoIcons.IoIosInformationCircleOutline />}
            value={profile?.fullname}
            onChange={onChangeValue}
            name="fullname"
          />
          <TextInput
            type="text"
            placeholder="Email"
            iconLeft={<IoIcons.IoIosMail />}
            iconRight={<MdIcons.MdEdit />}
            editable={false}
            value={secureText(profile.email)}
            onChange={onChangeValue}
            name="email"
            onOpen={() => setType({ type: "changeEmail", isOpen: true })}
          />
          <TextInput
            type="password"
            placeholder="Password"
            iconLeft={<IoIcons.IoMdKey />}
            iconRight={<MdIcons.MdEdit />}
            editable={false}
            value="****************"
            name="password"
            onOpen={() => setType({ type: "changePassword", isOpen: true })}
          />
          <TextInput
            type="text"
            placeholder="Question"
            iconLeft={<BsIcons.BsQuestionDiamondFill />}
            iconRight={<MdIcons.MdEdit />}
            editable={false}
            value={profile.question?.qs_text}
            name="question"
            onOpen={() => setType({ type: "changeQuestion", isOpen: true })}
          />
          {profile.fullname !== userProfile.fullname && (
            <button type="submit" className="w-2/12 py-2 bg-blue-100 rounded-md">
              <span className="text-blue-900">Update</span>
            </button>
          )}
          {/* captcha */}
          <ReCAPTCHA sitekey={process.env.REACT_APP_SITE_KEY} size="invisible" ref={reRef} />
        </Box>
      </WrapInfromation>
    </>
  );
};
const WrapInfromation = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  max-width: 1920px;
  justify-content: center;
  align-items: center;
`;
const Box = styled.form`
  display: flex;
  width: 50%;
  height: 50%;
  background-color: rgba(255, 255, 255, 0.4);
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 16px;
`;

export default Information;
